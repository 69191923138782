﻿$small-mobile: 320px;
$small-device: 576px;
$medium-device: 768px;
$large-device: 992px;
$extra-large-device: 1200px;

@mixin respond-min( $width ) {
    @media only screen and ( min-width: $width ) {
        @content;
    }
}
@mixin respond-max( $width ) {
    @media only screen and ( max-width: $width ) {
        @content;
    }
}
