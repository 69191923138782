@import '../base/colours.scss';
@import '../base/media.scss';
@import '../base/mixins.scss';

.action-buttons {
    display: flex;
    flex-flow: row wrap;
    margin-top: 13px;

    @include respond-min($small-device) {
        margin-top: 32px;
    }

    & > .button, & > .paypal-button {
        flex: 100%;
        margin-bottom: 16px;
        cursor: pointer;

        @include respond-min($small-device) {
            flex: 0 1 auto;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.button-primary {
    @include button-style($button-primary-normal-background-colour, $button-primary-normal-text-colour, $button-primary-hover-background-colour, $button-primary-hover-text-colour, $button-normal-padding);
    @include button-sizes(16px, 16px, 20px);
    text-transform: uppercase;
}

.button-secondary {
    @include button-style($button-secondary-normal-background-colour, $button-secondary-normal-text-colour, $button-secondary-hover-background-colour, $button-secondary-hover-text-colour, $button-normal-padding);
    @include button-sizes(16px, 16px, 20px);
    text-transform: uppercase;
}

.button-ghost {
    @include button-style($button-ghost-normal-background-colour, $button-ghost-normal-text-colour, $button-ghost-hover-background-colour, $button-ghost-hover-text-colour, .5em 1.3em .55em, 2px, 35px);
    @include button-sizes(16px, 16px, 16px);
    text-transform: uppercase;
}

.button-payment {
    @include button-style($button-primary-normal-background-colour, $button-primary-normal-text-colour, $button-primary-hover-background-colour, $button-primary-hover-text-colour, 0.4em 1em);
    @include button-sizes(16px, 16px, 20px);
    @include button-icon-left($image-button-normal-card, 25px, 37px, 15px, 'cover');
    text-transform: uppercase;

    & > span {
        margin-left: 20px;
    }
    /*&:hover:before {
        background-image: url($image-button-hover-card);
    }*/
}

.button-icon-edit {
    @include button-icon-left($image-button-normal-update, 27px, 27px, 15px);

    &:hover:before {
        background-image: url($image-button-hover-update);
    }
}

.button-icon-video {
    @include button-icon-left($image-button-normal-video, 25px, 25px, 15px);

    &:hover:before {
        background-image: url($image-button-hover-video);
    }
}

.button-icon-photo {
    @include button-icon-left($image-button-normal-photo, 23px, 27px, 15px);

    &:hover:before {
        background-image: url($image-button-hover-photo);
    }
}

.button-icon-donation {
    @include button-icon-left('#{$image-button-normal-donation}', 24px, 22px, 15px);

    &:hover:before {
        background-image: url($image-button-hover-donation);
    }
}

.button-icon-delete {
    @include button-icon-left('/assets/images/icons/waste-bin-blue.svg', 23px, 22px, 15px);

    &:hover:before {
        background-image: url("/assets/images/icons/waste-bin-white.svg");
    }
}

.button-link-arrow {
    @include button-icon-right('/assets/images/icons/link-arrow.svg', 23px, 22px, 15px);

    &:hover:after {
        transform: translateX(25%);
        transition: right 1s ease;
    }
}
