.edit-photo {
    .thumbnails {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        .thumbnail-item {
            margin-right: 1em;
            margin-bottom: 1em;
            display: flex;
            flex-direction: column;
            align-items: center;

            .input-container {
                margin: 1em 0;
            }

            & > .thumbnail-photo {
                display: block;
                height: 100%;
                width: 100%;
            }
        }
    }
}
