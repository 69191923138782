@import '../base/colours.scss';
@import '../base/mixins.scss';


form.dropzone {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 2em 0;
    max-width: 570px;
    height: 298px;
    background-color: #ECEBEB;
    border: 1px solid #b7b7b7;

    .dz-message {
        span {
            font-size: 20px;
            font-weight: 600;
            text-decoration: underline;
            text-align: center;
        }
    }

    .dz-image-preview {
        height: 100%;
        width: 100%;
        max-height: 100%;
        background-color: transparent;

        .dz-image {
            height: 100%;
            width: 100%;
            background-color: #ECEBEB;
            border-radius: 0;

            & > img {
                margin: 0 auto;
                height: 100%;
            }
        }

        .dz-remove {
            margin: 1em auto 0;
            @include button-style( $button-ghost-normal-background-colour, $button-ghost-normal-text-colour, $button-ghost-hover-background-colour, $button-ghost-hover-text-colour, 0.5em 1em 0.45em, 2px, 25px, 16px);
            position: absolute;
            bottom: -18px;
            left: calc(50% - 98px);
            width: 196px;
            z-index: 30;
        }
    }
}
