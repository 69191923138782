@import '../base/media.scss';


/*h2 {
    font-size: 24px;

    @include respond-min($medium-device) {
        font-size: 36px;
    }
}
*/

.rte {

    //p, ul, ol {
    //    margin-bottom: 30px;
    //}

    //p:last-of-type{
    //    margin-bottom:0;
    //}

    ul, ol {
        line-height: 30px;
        margin-left: 30px;
        margin-bottom: 30px;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    //a {
    //    text-decoration: underline;
    //}

    strong {
    }

    em {
        font-style: italic;
    }

    p img, .embeditem {
        max-width: 100% !important;
        height: auto !important;
    }
}
