@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';
@import '../base/mixins.scss';

.home-page {
    .jumbotron {
        position: relative;

        .jumbotron-carousel {
            position: relative;
            display: block;
            height: 192px;
            width: 100%;

            @include respond-min($medium-device) {
                position: absolute;
                height: 100%;
            }

            &.owl-carousel {
                .owl-stage-outer, .owl-stage, .owl-item {
                    height: 100%;
                }

                .owl-dots {
                    position: absolute;
                    bottom: 18px;
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .owl-dot {
                        span {
                            display: block;
                            margin: 5px;
                            border: 3px solid #ffffff;
                            border-radius: 20px;
                            height: 18px;
                            width: 18px;
                        }

                        &.active span {
                            background: none repeat scroll 0 0 #ffffff;
                        }
                    }
                }
            }

            .jumbotron-image {
                height: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        .wrapper {
            padding: 0 1em;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            max-width: 1130px;
            background-color: #f5f5f5;

            @include respond-min($medium-device) {
                padding: 0 28px;
                flex-flow: row nowrap;
                background-color: transparent;
            }
        }

        .jumbotron-content {
            flex: 0 1 auto;
            margin: 2em 0;
            max-width: 440px;

            .title, p {
                color: #000;
            }

            p {
                max-width: 670px;
            }

            @include respond-min($medium-device) {
                margin: 3em 0 4em;

                .title, p {
                    color: #fff;
                }
            }

            @include respond-min($large-device) {
                margin: 6em 0;
                max-width: 720px;
            }



            .action-buttons {
                .button-normal {
                    @include button-style($button-secondary-normal-background-colour, $button-homecta-normal-text-colour, $button-homecta-normal-hover-background-colour, $button-homecta-normal-hover-text-colour, 0.7em 2em);
                    @include button-sizes(16px, 16px, 20px);
                    text-transform: uppercase;

                    @include respond-min($medium-device) {
                        background-color: $button-homecta-normal-hover-background-colour;
                    }
                }
            }
        }

        & .owl-dots {
            position: absolute;
            bottom: 18px;
            width: 100%;
            display: flex;
            justify-content: center;
            z-index: 51;

            .owl-dot {
                span {
                    display: block;
                    margin: 5px;
                    border: 3px solid #ffffff;
                    border-radius: 20px;
                    height: 18px;
                    width: 18px;
                }

                &.active span {
                    background: none repeat scroll 0 0 #ffffff;
                }
            }
        }
    }

    .featured-funds {
        padding: 2em 1em;

        @include respond-min($medium-device) {
            padding: 3em 0;
        }

        .wrapper {
            max-width: 1130px;
        }

        .title {
            margin: 0 auto;
            text-align: center;
        }

        .owl-carousel {
            margin-top: 1.4em;

            .owl-nav {
                .owl-prev, .owl-next {
                    @include image-icon($image-carousel-arrow-icon, 32px, 32px);
                    //background-size: contain;//this is a problem ie
                }

                .owl-prev {
                    @include respond-min($medium-device) {
                        margin-left: -50px;
                    }
                }

                .owl-next {
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);

                    @include respond-min($medium-device) {
                        margin-right: -50px;
                    }
                }
            }
        }

        .funds-carousel-item {
            flex: 1 0 auto;
            margin: 0 auto;
            padding: 0;
            max-width: 205px;

            @include respond-min($medium-device) {
                margin: 0;
                max-width: 100%;
            }

            a {
                text-decoration: none;
            }

            .funds-carousel-item-image {
                position: relative;
                margin: 0 auto;
            }

            .funds-carousel-item-name {
                margin-top: 0.8em;
                @include button-style($button-secondary-normal-background-colour, $button-secondary-normal-text-colour, $button-secondary-hover-background-colour, $button-secondary-hover-text-colour, 0 40px 0 20px, 0, 0, left);
                @include button-sizes(16px, 16px, 19px);
                text-align: left;
            }
        }
    }
}