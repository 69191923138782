@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';

.pagination-wrapper {
    display: flex;
    justify-content: flex-start;
}

.pagination {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100%; //for backwards compatiblity

    .page-count {
        margin: 0 1em;
    }

    &.pagination-numbers {
        a {
            margin-top: 4px;
            height: 42px;
            width: 42px;
            padding: 10px 0;
            border: 1px solid #0A0A0A;
            text-decoration: none;
            text-align: center;
            font-weight: bold;

            & + a {
                margin-left: 4px;
            }

            &.link-previous {
                border-radius: 8px 0 0 8px;
                background: url('/Images/nav-previous-arrow.svg') 50% 50% no-repeat;
            }

            &.link-next {
                border-radius: 0 8px 8px 0;
                background: url('/Images/nav-next-arrow.svg') 50% 50% no-repeat;
            }

            &.actv, &.disabled {
                border: 0;
                background-color: #999999;
            }
        }
    }
}
