@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';


.steps {
    flex: 0 1 100%;
    margin-bottom: 2em;
    padding: 10px 12px;
    //background-color: #eee;
    border-bottom: solid 1px #eee;

    @include respond-min($large-device) {
        padding: 12px 20px;
    }

    li {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;

        & > * {
            font-size: 14px;
            font-weight: bold;

            @include respond-min($large-device) {
                font-size: 17px;
            }
        }

        & + li {
            margin-left: 1.5em;

            @include respond-min($large-device) {
                margin-left: 2.5em;
            }
        }
    }

    .step-box {
        margin: 0;
        padding: 6px;
        height: 31px;
        width: 31px;
        border-radius: 50%;
        background-color: #F5F5F5;
        color: #000000;
        text-align: center;

        @include respond-min($large-device) {
            padding: 4px;
        }
    }

    span {
        display: none;
        margin-left: 8px;
        color: $steps-text-colour;
        font-weight: 500;

        @include respond-min($small-device) {
            display: block;
        }

        @include respond-min($large-device) {
            margin-left: 15px;
        }
    }

    .active {
        .step-box {
            color: #ffffff;
            background-color: $active-background-colour;
        }

        span {
            font-weight: 700;
            color: $steps-text-colour;
        }
    }

    .completed {
        .step-box {
            position: relative;
            background-color: $completed-background-colour;

            &:after {
                display: block;
                position: absolute;
                top: 9px;
                left: 7px;
                height: 18px;
                width: 18px;
                background-image: url(/assets/images/icons/step-completed-tick.svg);
                background-size: contain;
                background-repeat: no-repeat;
                content: '';
            }
        }

        span {
            color: $steps-text-colour;
        }
    }
}
