@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';

footer {
    padding: 2.5em 0;
    background-color: $footer-background-colour;
    color: $footer-text-colour;

    .wrapper {
        max-width: $wrapper-width;


        @include respond-min($medium-device) {
            padding: 0 28px;
        }

        @include respond-min($extra-large-device) {
            padding: 0 20px;
        }
    }

    .logo {
        height: 100%;
        width: 100%;
    }

    .brand {
        margin: 0;
        height: 45px;

        @include respond-min($medium-device) {
            flex: 0 1 auto;
            height: 58px;
            width: 198px;
        }

        .logo {
            background: url(/assets/images/brand-logo-footer.svg) no-repeat;
            background-position: center;
            background-size: contain;
        }
    }

    .logos {
        margin: 0 auto;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > * + * {
            margin-left: 1em;
            padding-left: 1em;
            border-left: 1px solid #ffffff;
        }

        .addition {
            height: 50px;
            width: 100px;

            @include respond-min($medium-device) {
                height: 70px;
                width: 130px;
            }

            .logo {
                background: url(/assets/images/footer/footer_powered_logo.svg) no-repeat;
                background-position: bottom center;
                background-size: contain;
                font-size: 11px;
                color: #ffffff;

                @include respond-min($medium-device) {
                    font-size: 14px;
                }
            }
        }

        .fundraising-regulator {
            height: 50px;
            width: 180px;

            @include respond-min($medium-device) {
                height: 70px;
                width: 200px;
            }

            .logo {
                background: url('#{$image-fundraising-regulator-logo}') no-repeat;
                background-position: bottom center;
                background-size: contain;

                @include respond-min($medium-device) {
                    font-size: 14px;
                }
            }
        }
    }

    .social-links {
        flex: 0 1 100%;
    }

    .supplementary-links {
        flex: 0 1 100%;
        font-family: franklin-gothic-urw-demi;

        nav {
            max-width: 1240px;
            margin: 0 auto;

            ul {
                display: flex;
                flex-flow: column;
                justify-content: center;
                padding: 0 0 1.5rem;

                @include respond-min($medium-device) {
                    flex-flow: row wrap;
                }

                li {
                    padding: 0 2rem;
                    /*@include respond-min($medium-device) {
                        & + li {
                            margin-left: 2em;
                        }
                    }*/
                    a {
                        color: $footer-text-colour;
                        text-decoration: none;

                        @include respond-min($medium-device) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .legal-copy {
        flex: 1 1 100%;
        margin-top: 10px;

        p {
            &, span {
                color: $footer-text-colour;
                text-align: center;
            }
        }
    }

    .scroll-top-button {
        display: none;
        position: fixed;
        bottom: 20px;
        right: 30px;
        z-index: 99;
        font-size: 18px;
        border: none;
        outline: none;
        background-color: red;
        color: white;
        cursor: pointer;
        padding: 15px;
        border-radius: 4px;
    }
}
