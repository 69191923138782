@import '../base/media.scss';

.owl-carousel {
    .owl-item {
        margin: 0;
    }

    .owl-nav {
        margin: 0;

        .owl-next,
        .owl-prev {
            margin: 0;
            position: absolute;
            top: calc(50% - 52px);

            @include respond-min($small-device) {
                top: calc(50% - 32px);
            }
        }

        .owl-prev {
            left: 0
        }

        .owl-next {
            right: 0;
        }
    }
}
