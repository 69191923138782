﻿@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';

@mixin image-icon($url, $height, $width, $bgSize: "contain") {
    display: block;
    height: $height;
    width: $width;
    background: url($url) 50% 50% no-repeat;
    background-size: $bgSize; //changed $height, $width to contain for IE compatibily
    z-index: 1;
}

// START - Button mixins ************************************** //

@mixin button-style($normal-background-colour, $normal-text-colour, $hover-background-colour, $hover-text-colour, $padding, $border-width: 0, $border-radius: 0, $text-align: center) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $padding;
    width: auto;
    height: 55px;
    border: solid $border-width $normal-text-colour;
    border-radius: $border-radius;
    background-color: $normal-background-colour;
    font-family: 'franklin-gothic-urw-demi';

    &, span {
        color: $normal-text-colour;
        text-align: $text-align;
        text-decoration: none;
    }

    &:hover {
        background-color: $hover-background-colour;

        &, span {
            color: $hover-text-colour;
            text-decoration: none;
        }
    }
}

@mixin button-sizes($font-size-mobile, $font-size-tablet, $font-size-desktop) {
    &, span {
        font-size: $font-size-mobile;

        @include respond-min($large-device) {
            font-size: $font-size-tablet;
        }

        @include respond-min($extra-large-device) {
            font-size: $font-size-desktop;
        }
    }
}


@mixin button-icon-left($url, $height, $width, $left, $bgSize: "contain") {
    position: relative;
    padding-left: 65px;

    &:before {
        position: absolute;
        top: calc(50% - (#{$height} / 2));
        left: $left;
        content: '';
        @include image-icon($url, $height, $width, $bgSize)
    }
}

@mixin button-icon-right($url, $height, $width, $right) {
    position: relative;
    padding-right: 65px;

    &:after {
        position: absolute;
        top: calc(50% - (#{$height} / 2));
        right: $right;
        content: '';
        @include image-icon($url, $height, $width)
    }
}

// END -  Button mixins ************************************** //


@mixin required-asterisk {
    @content;
    content: '*';
    color: $validation-colour-negative;
}

@mixin account-rasied($font-size: $donation-amount-font-size, $font-family: $donation-amount-font-family) {
    margin: .25em 0;
    color: $account-raised-text-colour;
    font-family: $font-family;
    font-size: $font-size;
    text-align: left;
}

@mixin event-date($colour) {
    font-family: "franklin-gothic-urw-book",sans-serif;
    margin-bottom: 0.6em;
    color: $colour;
    @content;
}

@mixin link($include-icon: false) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 19px;
    color: #011d49;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

    @if $include-icon == true {
        &:after {
            display: block;
            margin-left: 12px;
            height: 15px;
            width: 22px;
            background-image: url(/assets/images/icons/link-arrow.svg);
            background-size: contain;
            background-repeat: no-repeat;
            content: '';
        }
    }
}
