@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';
@import '../base/mixins.scss';

input[type=text], input[type=email], input[type=password], input[type=number], input[type=tel], input[type=date], select, textarea, .hosted-fields {
    padding: 13px;
    width: 100%;
    background-color: $input-background-colour;
    border: 2px solid $input-border-colour;
    border-radius: 4px;
    font-size: 17px;
    color: #0A0A0A;
}

input[type=text], input[type=email], input[type=password], input[type=tel], select, .hosted-fields {
    max-width: 322px
}

input[type=date] {
    max-width: 200px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

select {
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url($image-input-select-arrow) no-repeat;
    background-position: 96% 50%;
    background-size: 20px;
}

.form-input-prefix {
    position: relative;
    margin-top: 11px;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 262px;

    &:before {
        padding: 7px 19px;
        height: 100%;
        width: 50px;
        border-radius: 4px 0 0 4px;
        background-color: $input-prefix-background-colour;
        color: $input-prefix-foreground-colour;
        font-family: 'franklin-gothic-urw-demi';
        font-size: 30px;
    }

    input {
        margin: 0 !important;
        height: 100%;
        width: 100%;
    }
}

.pound-sign:before {
    content: '£';
}

.hosted-fields {
    height: 50px;
}

.contact-preferences {
    margin: 2em 0;

    .heading {
        margin-bottom: 1em;
        padding-left: 180px;

        span {
            margin-left: 24px;
            font-family: 'franklin-gothic-urw-demi';
            text-transform: uppercase;
        }
    }

    & > .contact-preferences-option {
        display: flex;
        align-items: center;

        span {
            width: 200px;
            font-family: 'franklin-gothic-urw-book', sans-serif;
            font-weight: 700;
        }

        .input-container {
            height: 32px;

            & + .input-container {
                margin-left: 1em;
            }
        }

        & + .contact-preferences-option {
            margin-top: 2em;
        }
    }
}

.gift-aid {

    & > .title {
        @include button-icon-right("/assets/images/gift-aid.svg", 50px, 142px, 0);
    }

    .gift-aid-box {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        img {
            flex: 0;
            margin-right: 1em;
            max-width: 200px;
        }

        .gift-aid-options {
            flex: 1 260px;
            margin-top: 1em;

            @include respond-min($small-device) {
                margin-top: 0;
            }


            .title {
                display: block;
                margin: 1.5em 0 1em;
                font-weight: 600;
            }

            label.input-container .radiomark + span{
                margin-left: 12px;
            }
        }
    }
}


