@font-face {
    font-family: "franklin-gothic-ext-cond";
    src: url("/assets/fonts/FranklinGotExtCond.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "franklin-gothic-urw";
    src: url("/assets/fonts/FranklinGothicURW.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "franklin-gothic-urw-medium";
    src: url("/assets/fonts/FranklinGothicURWMedium.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "franklin-gothic-urw-cond-book";
    src: url("/assets/fonts/FranklinGothicURWConBoo.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "franklin-gothic-urw-demi";
    src: url("/assets/fonts/FranklinGothicURWDem.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "franklin-gothic-urw-book";
    src: url("/assets/fonts/FranklinGothicURWBoo.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

