@import '../base/media.scss';
@import '../base/mixins.scss';

aside {
    margin-top: 3em;
    flex: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @include respond-min($large-device) {
        margin: 0;
        margin-left: 3em;
        flex: 0 1 auto;
        width: 295px;
    }

    .box {
        margin: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;
        align-items: flex-start;

        & > * {
            flex: 1 1 auto;
        }

        & .align-center {
            align-items: center;
        }

        .responsive-image {
            max-width: 295px;
        }

        > .event, .tribute {
            padding: 4px 0;

            &:before {
                display: inline-block;
                height: 33px;
                width: 60px;
                background: url(/assets/images/icons/fund.svg) 50% 50% no-repeat;
                background-size: contain;
                background-position: left center;
                content: '';
                vertical-align: middle
            }
        }

        > .event {
            margin-top: 1em;

            &:before {
                background-image: url(/assets/images/icons/event.svg);
            }
        }
    }

    .heading {
        margin: 1em 0;
        font-size: 28px;
        text-align: left;
    }

    .sub-heading {
        font-size: 20px;
        text-align: center;
    }

    nav {
        margin: 1em 0;

        ul {
            flex-direction: column;
            align-items: stretch;

            & > li {
                & + li {
                    margin: 0;
                    margin-top: 1em;
                }

                a {
                    @include link(true);
                }
            }
        }
    }

    .page-security {
        margin-top: 2em;
        text-align: left;

        @include respond-min($large-device) {
            align-self: flex-end;
        }


        .title {
            margin-bottom: 10px;
            font-size: 18px;
            font-family: franklin-gothic-urw-medium;
        }

        .fundraising-regulator {
            display: block;
            height: 42px;
            width: 100%;

            .logo {
                height: 100%;
                width: 100%;
                background: url('#{$image-fundraising-regulator-dark-logo}') no-repeat;
                background-position: center;
                background-size: contain;
            }
        }

        .logos {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & > .logo {

                & > img {
                    width: 100%;
                }

                & + .logo {
                    margin-top: 0.4em
                }
            }

            @include respond-min($small-device) {
                flex-direction: row;

                & > * {
                    padding: 0 18px;

                    & + * {
                        margin: 0;
                        border-left: 2px solid #000000;
                    }
                }
            }
        }
    }
}
