.share {

}

.share {
    display: flex;
    justify-content: center;
    align-content: center;

    & > span + .custom_images {
        margin-left: 1.5em;
    }
    
    a {
        &, &:link, &:hover {
            text-decoration: none;
        }

        & > img {
            height: 35px;
            width: 35px;
        }

        & + a {
            margin-left: 10px;
        }
    }
}