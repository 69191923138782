@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';

input, select, textarea {
    &.input-validation-error {
        border-color: $validation-colour-negative;
        box-shadow: 0 0 3px rgba($validation-colour-negative, 0.5);
    }
}

input.input-validation-error {
    background-image: url($image-validation-cross) !important;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 20px 20px !important;
    background-position: 96% 50% !important;
}

.field-validation-error {
    display: block;

    &, p, span, a {
        color: $validation-colour-negative;
        font-size: 16px;
    }

    &.validation-error-box {
        margin: 1em 0;
        padding: 20px;
        background-color: rgba($validation-colour-negative, 0.2);

        &, p, span, a {
            font-size: 19px;
        }
    }
}

label.input-container {
    input.input-validation-error {
        & ~ .checkmark, & ~ .radiomark {
            border-color: $validation-colour-negative;
            background-color: rgba($validation-colour-negative, 0.2);

            & + span {
                color: $validation-colour-negative;
            }
        }
    }
}

p.error-message {
    color: $validation-colour-negative;
}