@import '../base/vars.scss';
@import '../base/media.scss';

.notification-panel {
    position: relative;
    flex: 100%;
    margin: 2em auto;
    padding: 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $notification-light-background;
    text-align: center;

    @include respond-min($large-device) {
        max-width: 820px;
    }

    &.notification-panel-grey {
        background-color: $notification-dark-background;
    }

    .icon-close {
        position: absolute;
        top: 12px;
        right: 12px;
        height: 20px;
        width: 20px;
        background: url("/assets/images/icons/close-black-icon.svg") no-repeat center;
    }

    .share {
        margin-top: 1em;
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }
}
