@import '../base/media.scss';

.donation {
    .action-buttons {
        row-gap: 10px;
    }
    /* Appl Pay button*/
    .apple-pay-button {
        display: block;
        -webkit-appearance: -apple-pay-button;
        -apple-pay-button-type: donate;
        width: 100%;
        max-width: 320px;
        height: 45px;

        @include respond-min($small-device) {
            display: inline-block;
            margin-right: 1em;
        }
    }

    .apple-pay-button-black {
        -apple-pay-button-style: black;
    }

    .apple-pay-button-white {
        -apple-pay-button-style: white;
    }

    .apple-pay-button-white-with-line {
        -apple-pay-button-style: white-outline;
    }

    #paypal-button {
        width: 100%;
        height: 55px;
        display: none;

        &.visible {
            display: block;
        }

        @include respond-min($medium-device) {
            margin-right: 1em;
            max-width: 350px;
        }
    }

    #credit-button, #btnCC {
        vertical-align: top;
        margin-right: 0;
        height: 55px;
        width: 100%;
        border-radius: 4px;
        justify-content: center;

        @include respond-min($medium-device) {
            margin-right: 10px;
            max-width: 350px;
        }
    }

    .field-group {
        &:first-of-type {
            margin-top: 0;
        }
    }

    .donation-statement {
        margin-bottom: 1em;
    }
}
