﻿$primary-background-colour: #ececec;
$primary-foreground-colour: #0a0a0a;
$primary-border-colour: #999999;
$primary-textshadow-colour: #2B3108;

$secondary-background-colour: #fff;
$secondary-foreground-colour: #606060;

/* Header & Footer */
$header-background-desktop-colour: #ffffff;
$header-background-mobile-colour: #011D49;
$header-foreground-desktop-colour: #000000;
$header-foreground-mobile-colour: #ffffff;

$header-border-colour: #E5E5E5;

$footer-background-colour: #011D49;
$footer-text-colour: #FFFFFF;

/* Buttons */
$button-primary-normal-background-colour: #FF8E00;
$button-primary-normal-text-colour: #000000;
$button-primary-hover-background-colour: #FF4F00;
$button-primary-hover-text-colour: #000000;

$button-secondary-normal-background-colour: #38D2E8;
$button-secondary-normal-text-colour: #000000;
$button-secondary-hover-background-colour: #32BDD0;
$button-secondary-hover-text-colour: #000000;

$button-ghost-normal-background-colour: #ffffff;
$button-ghost-normal-text-colour: #011D49;
$button-ghost-hover-background-colour: #011D49;
$button-ghost-hover-text-colour: #FFFFFF;

$button-homecta-normal-text-colour: #000000;
$button-homecta-normal-hover-text-colour: #000000;
$button-homecta-normal-hover-background-colour: #fafafa;

$button-homecta-hover-text-colour: #fafafa;
$button-homecta-hover-background-colour: #fafafa;



/* Lines */
$horizontal-line-colour: #d2d2d2;

/* State */
$active-background-colour: #011D49;
$completed-background-colour: #011D49;
$steps-text-colour: #000000;

/* Inputs */
$input-background-colour: #FFFFFF;
$input-border-colour: #767676;
$input-selected-colour: #011D49;

$input-prefix-background-colour: #011D49;
$input-prefix-foreground-colour: #FFFFFF;

$input-selected-colour: #011D49;

$input-prefix-background-colour: transparent;
$input-prefix-foreground-colour: #011D49;

/* Validation */
$validation-colour-negative: #EE3350;
$validation-colour-positive: #74C050

