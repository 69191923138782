@import "../base/mixins.scss";
@import '../base/media.scss';

h1, h2, h3, h4 {
    margin: 0;
    margin-bottom: 0.2em;
    text-transform: uppercase;
    line-height: 1;
}

h1 {
    font-family: "franklin-gothic-ext-cond",sans-serif;
    font-size: 40px;
    line-height: 1em;
    letter-spacing: 0.3px;

    @include respond-min($medium-device) {
        font-size: 64px;
    }
}

h2 {
    font-family: "franklin-gothic-ext-cond",sans-serif;
    font-size: 26px;
    line-height: 1em;
    letter-spacing: 0.3px;

    @include respond-min($medium-device) {
        font-size: 48px;
    }
}

h3 {
    font-family: "franklin-gothic-urw-cond-book",sans-serif;
    font-size: 21px;
    line-height: 1em;
    letter-spacing: 0.3px;
    text-transform: initial;

    @include respond-min($medium-device) {
        font-size: 30px;
    }
}

h4 {
    font-family: "franklin-gothic-urw-demi",sans-serif;
    font-size: 22px;
}

input, p, span {
    color: $primary-foreground-colour;
}

input, p, span, a {

    font-size: 16px;

    @include respond-min($medium-device) {
        font-size: 19px;
    }
}

p, span {
    line-height: 24px;

    @include respond-min($medium-device) {
        line-height: 29px;
    }
}

time {
   @include event-date(#000000);
   font-size: 22px;
}

.introduction {
    margin-bottom: 1em;
}

.grid-layout {
    margin: 1em 0;
    display: flex;
    flex-flow: row wrap;

    .label {
        flex: 66px;
        font-weight: bold;

        + span {
            flex: 84%;
        }
    }
}