@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';
@import '../base/mixins.scss';

.page-contents.general {
    p + p {
        margin-top: 0em;
    }
}
