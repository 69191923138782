@import '../base/colours.scss';

label.input-container {
    display: block;
    position: relative;
    padding: 1px;
    padding-left: 35px;
    cursor: pointer;
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
            & ~ .checkmark:after {
                content: " ";
                display: block;
                position: absolute;
                left: 9px;
                top: 4px;
                width: 10px;
                height: 17px;
                border: solid $input-selected-colour;
                border-width: 0 5px 5px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            & ~ .radiomark:after {
                content: " ";
                display: block;
                position: absolute;
                left: 4px;
                top: 4px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: $input-selected-colour;
            }
        }
    }

    .checkmark, .radiomark {
        position: absolute;
        top: 0;
        left: 0;
        height: 32px;
        width: 32px;
        background-color: $input-background-colour;
    }

    .checkmark {
        border: 2px solid $input-border-colour;
        border-radius: 4px;
    }

    .radiomark {
        border: 2px solid $input-border-colour;
        border-radius: 50%;
    }

    &:hover input {
        & ~ .checkmark, & ~ .radiomark {
            background-color: #ccc;
        }
    }

    .checkmark, .radiomark {
        & + span {
            display: inline-block;
            padding: 3px;
            padding-left: 12px;
            font-family: 'franklin-gothic-urw-book';
            font-weight: normal;
        }
    }
}
