@import '../base/vars.scss';
@import '../base/colours.scss';
@import '../base/media.scss';

form {
    fieldset {
        display: block;

        & + fieldset {
            margin-top: 1em;
        }

        p + .field-group {
            margin-top: 1.2em;
        }

        .field-group {
            margin-bottom: 1.2em;

            &:after {
                content: "";
                clear: both;
                display: table;
            }

            label, .field-group-label {
                display: block;
                font-family: 'franklin-gothic-urw-medium';
                font-size: 18px;
            }

            label {
                & + input, & + select, & + textarea, & + .information, & + .hosted-fields {
                    margin-bottom: 0.3em;
                }
            }

            .textarea-wrapper {
                position: relative;
                display: block;
                width: 100%;
                max-width: 517px;

                .characters-remaining {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    font-size: 14px;
                    color: #606060;
                }
            }

            #card-image {
                position: absolute;
                top: 0.35em;
                right: 0.3em;
                width: 44px;
                height: 28px;
                background-color: white;
                background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/346994/card_sprite.png);
                background-size: 86px 458px;
                background-position: -100px 0;
                background-repeat: no-repeat;
                margin-bottom: 1em;

                &.visa {
                    background-position: 0 -398px;
                }

                &.master-card {
                    background-position: 0 -281px;
                }

                &.american-express {
                    background-position: 0 -370px;
                }

                &.discover {
                    background-position: 0 -163px;
                }

                &.maestro {
                    background-position: 0 -251px;
                }

                &.jcb {
                    background-position: 0 -221px;
                }

                &.diners-club {
                    background-position: 0 -133px;
                }
            }

            input[disabled], input[readonly] {
                background-color: #efefef;
            }
        }
    }

    .action-buttons {
        margin-top: 3em;
    }

    .create-account {
        margin-top: 20px;
    }

    #your-details, #payment-buttons, #payment-form, #complete-my-donation {
        display: none;
    }

    #complete-my-donation {
        margin-top: 20px;
    }

    #hosted-fields {
        position: relative;

        #btn-overlay {
            position: absolute;
            width: 100%;
            height: 250px;
            top: 0;
            left: 0;
            z-index: 1000;
            cursor: pointer;
        }
    }

    #payment-buttons {
        #btns {
            position: relative;
            margin: 20px 0;

            @include respond-min($extra-large-device) {
                button {
                    max-width: calc(40% - 10px);
                }
            }
        }
        //don't style, and make space, if this button is hidden (no GPay available)
        #payment-request-button:not([style="display: none;"]) {
            vertical-align: top;

            @include respond-min($extra-large-device) {
                max-width: calc(33% - 10px);
            }

            display: inline-block !important;
            width: 100%;
            margin-right: 0px;

            @include respond-min($medium-device) {
                margin-right: 10px;
            }

            div {
                height: 100%;
            }
        }

        button {
            vertical-align: top;
            margin-right: 0;
            height: 45px;
            width: 100%;
            max-width: 350px;
            border-radius: 4px;

            @include respond-min($medium-device) {
                margin-right: 10px;
            }

            &#btnStripe span {

                img {
                    position: relative;
                    top: 2px;
                }
            }
        }

        @media screen and (max-width: 1200px) {
            button, #payment-request-button {
                max-width: none;
            }
        }


    }
}
